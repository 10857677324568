import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import Headroom from 'headroom.js';
import sal from 'sal.js';
import 'slick-carousel';
import 'magnific-popup';

const Fiziospinal = {
  init() {
    this.cache();
    this.events();
    this.initSal();
    this.initSliders();
    this.initHeadroom();
    this.initPopup();
    this.initProgressiveLoading();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on('click', '.js-hamburger', event => this.showMobileNav(event));
    this.$doc.on('click', '.js-share', event => this.openShareWindow(event));
  },
  initSal() {
    sal();
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, { offset: 100, tolearnce: 5 });
    headroom.init();
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    if (this.$mobileNav.hasClass('is-active')) {
      this.$hamburger.attr('aria-pressed', 'true');
    } else {
      this.$hamburger.removeAttr('aria-pressed');
    }
  },
  initSliders() {
    if($('.js-usluge-slider').length > 0) {
      $('.js-usluge-slider').each(function(idx, el) {
        $(el).slick({
          dots: false,
          arrows: false,
          cssEase: 'linear',
          fade: true,
          speed: 800,
          autoplay: true,
          autoplaySpeed: 3000,
          infinite: true
        });
      });
    }
    if($('.js-usluge-related').length > 0) {
      $('.js-usluge-related').each(function(idx, el) {
        $(el).slick({
          dots: false,
          arrows: true,
          speed: 800,
          autoplay: true,
          autoplaySpeed: 5000,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
        });
      });
    }
  },
  initPopup() {
    $('.gallery-item').magnificPopup({
      type: 'image',
      gallery:{
        enabled:true,
        preload: [1,2]
      },
    });
  },
  initProgressiveLoading() {
    $('.progressive-image').each(function(){
      var image = new Image();
      //console.log(image);
      var previewImage = $(this).find('.loadingImage');
      //console.log(previewImage);
      var newImage = $(this).find('.overlay');
      //console.log(newImage);
      image.onload = function(){
          newImage.css('background-image', 'url(' + image.src + ')');
          newImage.css('opacity', '1');
          previewImage.hide();
          //console.log('complete');
      };

      image.src = previewImage.data('image');
    });
  }
};

Fiziospinal.init();
